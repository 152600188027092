import React from "react"
import Layout from "../components/layout"

import Seo from "../components/seo"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"

import "./404.module.scss"
import headerStyles from "./404.module.scss"

const NotFound= () => {
	return (
		<Layout>
			<Seo title="404 - Запрашиваемая страница не существует" description="Агентство недвижимости Алмаз Казань страница 404" />
			
			<div className={ `uk-section.uk-section-xsmall uk-margin-medium-top` }>
            	<div className={ `uk-container` }>

					<ul className={ `uk-breadcrumb` }>
						<li><a href="/">Главаная</a></li>
                        <li><span>404</span></li>
					</ul>

				</div>
			</div>

			<div className={ `uk-section uk-section-small` }>
            	<div className={ `uk-container` }>

                    <h2 className={ `${headerStyles.errorHeader}` }>Страница не существует</h2>

                    <div>
                        <img className={ `${headerStyles.errorImg}` }  src="/img/404.png" alt="Страница не существует"></img>  
                    </div>

				</div>
			</div>
		
			<Sale />
			<Call />
			<CallForm />
			<ContactForm />
			<Stado />

		</Layout>
	)
}

export default NotFound
